<template>
  <div class="the-navbar__user-meta flex items-center">
    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div :key="key" class="text-right leading-tight hidden sm:block pl-4 pr-4">
        <div class="flex flex-col items-center">
          <font-awesome-icon icon="university" style="font-size: 20px;"/>
        </div>
        <div class="flex-col justify-center" v-if="hideActiveProfile">
          <small v-if="activeInstitution.id"
            v-tooltip.bottom="{
              content: activeInstitution.name,
              delay: { show: 100, hide: 500 },
            }"
          class="w-24 truncate">{{activeInstitution.name}}</small>
        </div>
        <small v-else>{{ $t('pessoal') }}</small>
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown" v-if="userInstitutions && userInstitutions.length">
        <div class="p-2" v-if="userInstitutions.length > 0">
          <!-- Campo de entrada para o filtro -->
          <vs-input
            v-model="filterText"
            placeholder="Filtrar instituições"
            class="w-full p-2 border rounded mb-2"
          />
        </div>
        <ul style="min-width: 15rem">
          <vs-divider class="m-1" v-if="filteredInstitutions.length">{{ $t('fields.institution') }}</vs-divider>
          <li
            v-if="isAdmin()"
            :class="institutionHighlightStyle(null)"
            @click="setCurrentInstitution(null)"
          >
            <span class="inline-flex items-center">
              <font-awesome-icon icon="chalkboard-teacher"/>
            </span>
            <span class="ml-2">{{ $t('pessoal') }}</span>
          </li>
          <li
            v-for="institution in filteredInstitutions"
            :key="institution.id"
            :class="institutionHighlightStyle(institution.id)"
            @click="setCurrentInstitution(institution)"
          >
            <span class="inline-flex items-center">
              <font-awesome-icon icon="university"/>
            </span>
            <span class="ml-2">{{institution.name}}</span>
          </li>
        </ul>
      </vs-dropdown-menu>
      <vs-dropdown-menu v-else>
        <ul class="p-2">
          <li class="text-sm font-bold text-left"> {{ $t('voce-nao-esta-vinculado-a-nenhuma-instituicao') }} </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import { isAdmin } from '@/util/Util'
import { colors } from '@/../themeConfig'

export default {
  data() {
    return {
      key: 0,
      filterText: '' // Adiciona o campo de filtro
    }
  },
  computed: {
    userInstitutions() {
      return this.$store.getters['institution/available']
    },
    activeInstitution() {
      return this.$store.getters['institution/active']
    },
    hideActiveProfile() {
      const activeInstitution = this.$store.getters['institution/active']
      return !this.isEmpty(activeInstitution.id)
    },
    filteredInstitutions() {
      return this.userInstitutions.filter(institution => {
        return institution.name.toLowerCase().includes(this.filterText.toLowerCase())
      })
    }
  },
  watch: {
    '$store.state.AppActiveUser'(val) {
      this.key++
    },
    activeInstitution() {
      this.key++
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout', {vs: this.$vs})
    },
    isAdmin() {
      return isAdmin()
    },
    gotoProfile() {
      this.$router.push('/profile')
    },
    showRoleModal() {
      this.$store.dispatch('common/setRoleModalVisibility', true)
    },
    setCurrentuserPerson(person) {
      this.$store.dispatch('updateActivePerson', person)
    },
    setCurrentInstitution(institution) {
      this.$store.dispatch('common/updateSmallLogo', null)
      this.$store.dispatch('common/updateLogo', null)

      this.$store.dispatch('institution/selectActiveInstitution', institution && institution.id).then(
        (value) => {
          let permissions = null
          if (institution) {
            permissions = institution.permissions
            this.$acl.setPermissions(institution.permissions)
          } else {
            const userInfo = localStorage.getItem('userInfo')
            permissions = JSON.parse(userInfo).permissions
            this.$acl.setPermissions(permissions)
          }

          if (this.$router.currentRoute.name !== 'home' && this.$router.currentRoute.meta && this.$router.currentRoute.meta.rule) {
            const currentRoutePermissions = this.$router.currentRoute.meta.rule.split('|')
            let found = false
            const permit = permissions.find((permission) => {
              currentRoutePermissions.forEach((routePermission) => {
                if (permission.name === routePermission) {
                  found = true
                }
              })
              return found
            })
            if (!permit) this.$router.push('/')
          }

          // Update theme
          if (institution && institution.organization && institution.organization.custom_data) {
            const customData = JSON.parse(institution.organization.custom_data)
            if (customData && customData.primaryColor) {
              this.$store.commit('UPDATE_PRIMARY_COLOR', customData.primaryColor)
              this.$vs.theme({ primary: customData.primaryColor })
            }
          } else {
            const defaultThemePrimaryColor = this.$store.getters.defaultThemePrimaryColor
            console.log('defaultThemePrimaryColor', defaultThemePrimaryColor)

            this.$store.commit('UPDATE_PRIMARY_COLOR', defaultThemePrimaryColor)
            this.$vs.theme({ primary: defaultThemePrimaryColor })
          }

          this.notifySuccess(
            this.$vs,
            this.$t('institution.dropdown.institution_changed', {name: (institution ? institution.name : this.$t('pessoal'))}),
            2000, 'center-top'
          )
        }
      ).finally(() => {
        this.$root.$emit('institution-changed', institution)
      })
    },
    institutionHighlightStyle(id) {
      const base = 'flex py-2 px-4 cursor-pointer'
      const hover = 'hover:bg-primary hover:text-white'
      const selected = 'bg-primary text-white'
      return `${base} ${hover} ${this.activeInstitution.id === id ? selected : ''}`
    }
  }
}
</script>
