/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    url: '/',
    name: 'dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard',
    rule: 'dashboard'
  },
  {
    url: '/users',
    name: 'users',
    icon: 'UserIcon',
    i18n: 'Users',
    rule: 'users.menu'
  },
  {
    url: '/contents',
    name: 'contents',
    icon: 'BookIcon',
    i18n: 'Contents',
    rule: 'contents.menu'
  },
  {
    url: '/physical_questionnaires',
    name: 'physical_questionnaires',
    icon: 'file-signature',
    iconPack: 'font-awesome',
    i18n: 'physical_questionnaires_title',
    rule: 'physical_questionnaires.menu',
    featherIcon: false
  },
  {
    url: '/questionnaires',
    name: 'questionnaires',
    icon: 'FileTextIcon',
    i18n: 'questionarios',
    rule: 'questionnaires.menu'
    /*,
      submenu: [
        {
          url: '/questionnaires',
          name: 'questionnaires',
          icon: 'FileTextIcon',
          i18n: 'Criar',
          rule: 'questionnaires.menu',
        },
        {
          url: '/questionnaires/answers',
          name: 'answers',
          icon: 'FileTextIcon',
          i18n: 'Respostas',
          rule: 'questionnaires.answers.menu',
        }
      ]*/
  },
  {
    url: '/base_question',
    name: 'base_question_menu',
    icon: 'ArchiveIcon',
    i18n: 'banco-de-questao',
    rule: 'base_question.menu'
  },
  {
    url: '/questionnaires/answers',
    name: 'answers',
    icon: 'CheckSquareIcon',
    i18n: 'meus-questionarios',
    rule: 'questionnaires.menu.answers',
  },// - Correction Questionnaire
  {
    url: '/',
    name: 'questionnaire_correctors.menu',
    icon: 'BookOpenIcon',
    i18n: 'questionnaire_corrector.title',
    rule: 'questionnaire_correctors.menu',
    submenu: [
      {
        url: '/questionnaire_correctors',
        name: 'questionnaire_correctors',
        icon: 'indent',
        iconPack: 'font-awesome',
        i18n: 'questionnaire_corrector.config',
        rule: 'questionnaire_correctors.config.menu',
        featherIcon: false
      },
      {
        url: '/questionnaire_correctors/corrections',
        name: 'questionnaire_corrector_correction',
        icon: 'indent',
        iconPack: 'font-awesome',
        i18n: 'questionnaire_corrector.correction',
        rule: 'questionnaire_correctors.teacher.menu',
        featherIcon: false
      },
      {
        url: '/questionnaire_correctors/dashboard',
        name: 'questionnaire_corrector_dashboard_index',
        icon: 'indent',
        iconPack: 'font-awesome',
        i18n: 'questionnaire_corrector.dashboard.title',
        rule: 'questionnaire_correctors.dashboard.menu',
        featherIcon: false
      },
    ],
  },//----End
  {
    url: '/',
    name: 'practical_tests.menu',
    icon: 'ActivityIcon',
    i18n: 'practical_test.title',
    rule: 'practical_tests.menu',
    submenu: [
      {
        url: '/practical_tests',
        name: 'practical_tests',
        icon: 'indent',
        iconPack: 'font-awesome',
        i18n: 'practical_test.config',
        rule: 'practical_tests.config.menu',
        featherIcon: false
      },
      {
        url: '/practical_tests/tests',
        name: 'practical_tests_teacher',
        icon: 'indent',
        iconPack: 'font-awesome',
        i18n: 'practical_test.avaliation',
        rule: 'practical_tests.teacher.menu',
        featherIcon: false
      },
      {
        url: '/practical_tests/dashboard',
        name: 'practical_tests_dashboard',
        icon: 'indent',
        iconPack: 'font-awesome',
        i18n: 'practical_test.dashboard.title',
        rule: 'practical_tests.dashboard.menu',
        featherIcon: false
      },
    ],
  },
  {
    url: '/campaigns',
    name: 'campaigns',
    icon: 'bullhorn',
    i18n: 'marketing.campaign.title',
    rule: 'campaigns.menu',
    iconPack: 'font-awesome',
    featherIcon: false
  },
  {
    url: '/organizations',
    name: 'organizations',
    icon: 'GlobeIcon',
    i18n: 'organizacoes',
    rule: 'organizations.menu'
  },
  {
    url: '/institutions',
    name: 'institutions',
    icon: 'university',
    i18n: 'institutions',
    rule: 'institutions.menu',
    iconPack: 'font-awesome',
    featherIcon: false
  },
  {
    url: '/courses',
    name: 'courses',
    icon: 'graduation-cap',
    i18n: 'courses',
    rule: 'courses.menu',
    iconPack: 'font-awesome',
    featherIcon: false
  },
  {
    url: '/disciplines',
    name: 'disciplines',
    icon: 'PenToolIcon',
    i18n: 'disciplines',
    rule: 'disciplines.menu'
  },
  {
    url: '/students',
    name: 'students',
    icon: 'UserCheckIcon',
    i18n: 'students',
    rule: 'students.menu'
  },
  {
    url: '/classes',
    name: 'classes',
    icon: 'UsersIcon',
    i18n: 'classes',
    rule: 'classes.menu'
  },
  {
    url: '/classes_students',
    name: 'classes_students',
    icon: 'UsersIcon',
    i18n: 'minhas-turmas',
    rule: 'classes.students.menu',
  },
  {
    url: '/chat',
    name: 'chat',
    icon: 'MessageCircleIcon',
    i18n: 'educatena-chat',
    rule: 'chat.menu'
  },
  {
    url: '/',
    name: 'marketing_place_menu',
    icon: 'ShoppingCartIcon',
    i18n: 'marketing_place.menu',
    rule: 'marketing_place.menu',
    submenu: [
      {
        url: '/products',
        name: 'products',
        icon: 'PocketIcon',
        i18n: 'product.menu',
        rule: 'products.menu'
      },
      {
        url: '/marketing_place',
        name: 'marketing_place',
        icon: 'PocketIcon',
        i18n: 'marketing_place.config',
        rule: 'marketing_place.config.menu'
      },
      {
        url: '/marketing_place/store',
        name: 'marketing_place_store',
        icon: 'PocketIcon',
        i18n: 'marketing_place.sales',
        rule: 'marketing_place.corporate.menu'
      },
      {
        url: '/orders',
        name: 'orders',
        icon: 'PocketIcon',
        i18n: 'order.menu',
        rule: 'orders.menu'
      },
    ]
  },
  {
    url: '/',
    name: 'proctoring_menu',
    icon: 'MonitorIcon',
    i18n: 'proctoring.menu',
    rule: 'proctoring.menu',
    submenu: [
      {
        url: '/proctoring_config',
        name: 'proctoring_config',
        icon: 'VideoIcon',
        i18n: 'supervisoes',
        rule: 'proctoring_config.show'
      },
      {
        url: '/proctoring_audit',
        name: 'proctoring_audit',
        icon: 'EyeIcon',
        i18n: 'auditoria',
        rule: 'proctoring.audit'
      },
      {
        url: '/proctoring_recording',
        name: 'proctoring_recording',
        icon: 'ActivityIcon',
        i18n: 'proctoring.menu',
        rule: 'proctoring.recording.menu',
      },
      {
        url: '/questionnaires/events',
        name: 'questionnaires_events',
        icon: 'ActivityIcon',
        i18n: 'sessao-eventos',
        rule: 'questionnaires.events.menu',
      },
    ]
  },
  {
    url: '/',
    name: 'certified_emit_menu',
    icon: 'PocketIcon',
    i18n: 'certified_emit.menu',
    rule: 'certified_emit.menu',
    submenu: [
      {
        url: '/certifieds',
        name: 'certifieds',
        icon: 'award',
        i18n: 'certified_emit.config',
        rule: 'certifieds.menu',
        iconPack: 'font-awesome',
        featherIcon: false
      },
      {
        url: '/certified_emit',
        name: 'certified_emit',
        icon: 'PocketIcon',
        i18n: 'certified_emit.emit',
        rule: 'certified_emit.grid'
      },
      {
        url: '/certified_emit/dashboard',
        name: 'certified_emit_dashboard_index',
        icon: 'indent',
        iconPack: 'font-awesome',
        i18n: 'certified_emit.dashboard.title',
        rule: 'certified_emit.dashboard.menu',
        featherIcon: false
      }
    ],
  },
  {
    url: '/reports',
    name: 'reports',
    icon: 'FileTextIcon',
    i18n: 'relatorios',
    rule: 'reports.menu',
    submenu: [
      {
        url: '/reports/questionnaire/presence',
        name: 'reports_questionnaire_presence',
        icon: 'FileTextIcon',
        i18n: 'presenca',
        rule: 'reports.questionnaire.presence',
      },
      {
        url: '/reports/questionnaire/score',
        name: 'reports_questionnaire_score',
        icon: 'FileTextIcon',
        i18n: 'notas',
        rule: 'reports.questionnaire.score',
      },
      {
        url: '/reports/questionnaire/performance',
        name: 'reports_questionnaire_performance',
        icon: 'FileTextIcon',
        i18n: 'desempenho',
        rule: 'reports.questionnaire.performance',
      },
      {
        url: '/reports/questionnaire/results',
        name: 'reports_questionnaire_results',
        icon: 'FileTextIcon',
        i18n: 'avaliacao-resposta',
        rule: 'reports.questionnaire.results',
      },
      {
        url: '/reports/questionnaire/pause',
        name: 'reports_questionnaire_pause',
        icon: 'ClockIcon',
        i18n: 'avaliacao-pausas',
        rule: 'reports.questionnaire.pause',
      },
      {
        url: '/reports/questionnaire/time',
        name: 'reports_questionnaire_time',
        icon: 'ClockIcon',
        i18n: 'avaliacao-duracao',
        rule: 'reports.questionnaire.time',
      },
      {
        url: '/reports/questionnaire/answer_change',
        name: 'reports_answer_change',
        icon: 'FileTextIcon',
        i18n: 'avaliacao-alteracao-de-resposta',
        rule: 'reports.questionnaire.answer_change',
      },
      {
        url: '/reports/questionnaire/sent_email',
        name: 'reports_questionnaire_sent_email',
        icon: 'FileTextIcon',
        i18n: 'report.sent_email.menu_title',
        rule: 'reports.questionnaire.sent_email',
      },
    ]
  },
  {
    url: '/dynamic_reports',
    name: 'dynamic_reports',
    icon: 'FileTextIcon',
    i18n: 'relatorios_dinamicos',
    rule: 'dynamic_reports.menu',
    submenu: [
      {
        url: '/dynamic_reports',
        name: 'dynamic_reports',
        icon: 'FileTextIcon',
        i18n: 'listar_relatorios_dinamicos',
        rule: 'dynamic_reports.grid',
      },
      {
        url: '/dynamic_report_executions',
        name: 'dynamic_report_executions',
        icon: 'FileTextIcon',
        i18n: 'gerar_relatorios_dinamicos',
        rule: 'dynamic_report_executions.menu',
      },
      {
        url: '/dynamic_report_executions_download',
        name: 'dynamic_report_executions_download',
        icon: 'FileTextIcon',
        i18n: 'baixar_relatorios_dinamicos',
        rule: 'dynamic_report_executions.download',
      }
    ]
  },
  {
    url: '/',
    name: 'settings',
    icon: 'SettingsIcon',
    i18n: 'configuracoes',
    rule: 'settings.menu',
    submenu: [
      {
        url: '/email_templates',
        name: 'email_templates',
        icon: 'MailIcon',
        i18n: 'modelos-de-email',
        rule: 'email_templates.menu'
      },
    ],
  },
  {
    url: '/user_client_exceptions',
    name: 'user_client_exceptions_index',
    icon: 'AlertTriangleIcon',
    i18n: 'excecoes-de-usuario',
    rule: 'general_system_admin',
  },
  {
    url: '/',
    name: 'communications',
    icon: 'MailIcon',
    i18n: 'comunicacao',
    rule: 'communications.menu',
    submenu: [
      {
        url: '/news',
        name: 'news',
        icon: 'bullhorn',
        i18n: 'avisos',
        rule: 'news.menu',
        iconPack: 'font-awesome',
        featherIcon: false
      },
      {
        url: '/missives',
        name: 'missives',
        icon: 'bullhorn',
        i18n: 'mala-direta',
        rule: 'missives.menu',
        iconPack: 'font-awesome',
        featherIcon: false
      },
      {
        url: '/emails_sent',
        name: 'emails_sent',
        icon: 'mail',
        i18n: 'emails_sent.menu',
        rule: 'emails_sent.menu',
        iconPack: 'font-awesome',
        featherIcon: true
      },
    ],
  },
  {
    url: '/certified_user',
    name: 'certified_user',
    icon: 'award',
    i18n: 'certified_user.title',
    rule: 'certified_user.menu',
    iconPack: 'font-awesome',
    featherIcon: false
  },
  {
    url: '/my_subscriptions',
    name: 'my_subscriptions',
    icon: 'hand-holding-usd',
    i18n: 'plan.subscription',
    rule: 'plans.subscription.menu',
    iconPack: 'font-awesome',
    featherIcon: false
  },
  {
    url: '/',
    name: 'backoffice_menu',
    icon: 'ToolIcon',
    i18n: 'backoffice.menu',
    rule: 'backoffice.menu',
    submenu: [
      {
        url: '/backoffice/questionnaire',
        name: 'backoffice_questionnaire_form',
        icon: 'tool',
        i18n: 'backoffice.questionnaire_menu',
        rule: 'backoffice.questionnaire',
        iconPack: 'font-awesome',
        featherIcon: true
      },
      {
        url: '/backoffice/user',
        name: 'backoffice_user_form',
        icon: 'tool',
        i18n: 'backoffice.user_menu',
        rule: 'backoffice.user',
        iconPack: 'font-awesome',
        featherIcon: true
      },
      {
        url: '/text_message',
        name: 'backoffice_text_message',
        icon: 'email',
        i18n: 'backoffice.text_notification',
        rule: 'backoffice.communications.text_notification',
        iconPack: 'font-awesome',
        featherIcon: true
      }
    ],
  },
  {
    url: '/',
    name: 'acl_menu',
    icon: 'PocketIcon',
    i18n: 'acl.menu',
    rule: 'acl.menu',
    submenu: [
      {
        url: '/roles',
        name: 'roles',
        icon: 'UsersIcon',
        i18n: 'Roles',
        rule: 'roles.menu'
      },
      {
        url: '/permissions',
        name: 'permissions',
        icon: 'UsersIcon',
        i18n: 'permission.menu',
        rule: 'permissions.menu'
      },
      {
        url: '/modules',
        name: 'modules',
        icon: 'PocketIcon',
        i18n: 'module.menu',
        rule: 'modules.menu'
      },
      {
        url: '/functionalities',
        name: 'functionalities',
        icon: 'PocketIcon',
        i18n: 'functionality.menu',
        rule: 'functionalities.menu'
      },
      {
        url: '/data_access',
        name: 'data_access',
        icon: 'UsersIcon',
        i18n: 'data_access.menu',
        rule: 'data_access.menu'
      },
    ],
  },
  {
    url: '/',
    name: 'plan_menu',
    icon: 'MoneyIcon',
    i18n: 'plan.menu',
    rule: 'plans.menu',
    submenu: [
      {
        url: '/plans',
        name: 'plan_grid',
        icon: 'MoneyIcon',
        i18n: 'plan.menu',
        rule: 'plans.grid'
      },
      {
        url: '/billing',
        name: 'plan_billing_grid',
        icon: 'MoneyIcon',
        i18n: 'billing.menu',
        rule: 'billing.grid'
      },
    ],
  },
  {
    url: '/',
    name: 'appeal_menu',
    icon: 'CheckIcon',
    i18n: 'appeal.menu',
    rule: 'appeals.menu',
    submenu: [
      {
        url: '/appeals',
        name: 'appeals_index',
        icon: 'CheckIcon',
        i18n: 'appeal.menu',
        rule: 'appeals.menu'
      },
      {
        url: '/appeal_handlings',
        name: 'appeal_handlings',
        icon: 'EyeIcon',
        i18n: 'appeal.handling.menu',
        rule: 'appeal_handlings.menu'
      },
    ]
  },
]
